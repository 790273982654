import Tags from "./tags";
import Text from "./Text";
import Textarea from "./Textarea";
import Password from "./Password";

export default {
  Tags,
  Text,
  Textarea,
  Password,
};
